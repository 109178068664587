<template>
  <div>
    <UserDetailinfo />
  </div>
</template>

<script>
import UserDetailinfo from "../../components/userDetailinfo.vue";
export default {
  name: "HRvecProvincialinfo",
  components: { UserDetailinfo },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
